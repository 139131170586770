import React, { useContext } from 'react'

const PAGE_SIZE = 50

export const paginationInitialValues = {
    offset: 0,
    limit: PAGE_SIZE
}

// prettier-ignore
export type PaginationParams = {
    offset: number;
    limit: number;
}

// prettier-ignore
type PaginationContextType = {
    params: PaginationParams;
    totalPages: number;
    totalResults: number;
    activePage: number;
    setPagesCount: (resultsCount: number, pagesCount?: number) => void;
    onChangeParams: (params: PaginationParams) => void;
    onChangeLimit: (limit: number) => void;
    onGoToPage: (page: number) => void;
}

export const PaginationContext = React.createContext<PaginationContextType>({
    params: paginationInitialValues,
    totalResults: 0,
    totalPages: 1,
    activePage: 1,
    setPagesCount: (_resultsCount: number, _pagesCount?: number) => {
        throw new Error('setPagesCount() not implemented')
    },
    onChangeParams: () => {
        throw new Error('onChangeParams() not implemented')
    },
    onChangeLimit: (_limit: number) => {
        throw new Error('onChangeLimit() not implemented')
    },
    onGoToPage: (_page: number) => {
        throw new Error('onGoToPage() not implemented')
    }
})

export const usePaginationContext = (): PaginationContextType => {
    return useContext(PaginationContext)
}
