import React, { useState, useCallback } from 'react'
import { useMount } from 'react-use'

import { useAppContext } from './AppContext'
import OptionsContext from './OptionsContext'

import { KEYS } from 'library/storage'
import { optionsService } from 'library/services/optionsService'

interface Props {}

const OptionsProvider: React.FC<Props> = ({ children }) => {
    const { setBackdropLoading } = useAppContext()
    const [options, setOptions] = useState<any>({
        languages: [],
        positions: [],
        companies: []
    })

    const loadOptions = useCallback(
        async (forceFetch: boolean = false) => {
            try {
                setBackdropLoading(true)

                if (forceFetch) {
                    await optionsService.fetchAllOptions()
                }

                const [languages, companies, positions] = await Promise.all([
                    optionsService.loadOptions(KEYS.LANGUAGES),
                    optionsService.loadOptions(KEYS.COMPANIES),
                    optionsService.loadOptions(KEYS.POSITIONS)
                    // FIXME: Add other options
                    // countries, statuses etc.
                ])

                setOptions({
                    languages,
                    positions,
                    companies
                })
            } catch (error) {
            } finally {
                setBackdropLoading(false)
            }
        },
        [setBackdropLoading]
    )

    useMount(() => {
        loadOptions()
    })

    return (
        <OptionsContext.Provider value={{ ...options, loadOptions }}>
            {children}
        </OptionsContext.Provider>
    )
}

export default OptionsProvider
