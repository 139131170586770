import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'

import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import { getCroppedImg } from './canvasUtils'

// prettier-ignore
interface Props {
    imageSrc: any;
    onImageSelected: (croppedImage: string) => void;
}

const ImageCropper: React.FC<Props> = ({ onImageSelected, imageSrc }) => {
    const classes = useStyles()

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState<any>(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const onCropComplete = useCallback((_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const saveCroppedImage = useCallback(async () => {
        try {
            const croppedImage: any = await getCroppedImg(imageSrc, croppedAreaPixels)

            if (onImageSelected) {
                onImageSelected(croppedImage)
            }
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, imageSrc, onImageSelected])

    const renderCropper = (
        <Box>
            <Grid className={classes.croppedContainer}>
                <Cropper
                    image={imageSrc}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            </Grid>
            <Slider min={1} max={3} step={0.1} value={zoom} onChange={(e, zoom) => setZoom(zoom)} />
            <Button
                fullWidth
                onClick={saveCroppedImage}
                size="small"
                variant="contained"
                color="primary">
                Crop image
            </Button>
        </Box>
    )

    return imageSrc ? renderCropper : null
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        croppedContainer: {
            position: 'relative',
            width: '100%',
            height: 200,
            background: '#333',
            [theme.breakpoints.up('sm')]: {
                height: 400
            }
        }
    })
)

export default ImageCropper
