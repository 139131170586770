import React, { useState, useCallback } from 'react'
import { useHistory } from 'react-router'

import { AuthContext } from './AuthContext'

import { authService } from 'library/services/authService'
import User from 'library/models/User'

const AuthProvider = (props: any) => {
    const history = useHistory()
    const [authenticated, setAuthenticated] = useState<boolean>(authService.isAuthenticated())
    const [user, setUser] = useState<User | any>(authService.loadUser())

    const setSession = useCallback(
        (user: any) => {
            setUser(user)
            setAuthenticated(true)

            history.push('/dashboard')
        },
        [history]
    )

    const logout = useCallback(() => {
        setAuthenticated(false)
        setUser(null)

        authService.logout()
    }, [])

    const isMe = useCallback(
        (userId: number | string) => {
            return user ? userId === user.id : false
        },
        [user]
    )

    const providerValue = {
        authenticated,
        user,
        isMe,
        setSession,
        logout
    }

    return <AuthContext.Provider value={providerValue}>{props.children}</AuthContext.Provider>
}

export default AuthProvider
