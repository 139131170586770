import React from 'react'

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'

interface Props {}

const Loader: React.FC<Props> = ({ ...props }) => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="70vh">
            <Container maxWidth="lg">
                <Box display="flex" justifyContent="center">
                    <CircularProgress color="primary" size={50} />
                </Box>
            </Container>
        </Box>
    )
}

export default Loader
