export default {
    primary: '#2C3848',
    secondary: '#343C48',
    accent: '#C0EE33',
    dark: '#2C3848',
    green: '#95DA0D',

    lightBackground: '#f9f9f9',
    lightGreyBg: '#E6E6E6',

    darkBlue: '#2D3947',
    lighterBlue: '#3F4851',

    lightGrey: '#9DA0A2',
    extremeLightGrey: '#FAFBFD',

    lightGreyFont: '#bebebe',
    mainBoxShadow: '0px 5px 5px 0px #C9C9C9',

    tableGreyBg: '#f4f4f4',

    paginationGrey: '#999999'
};
