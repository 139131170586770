import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { Switch, FormControlLabel, SwitchProps } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

// prettier-ignore
interface Props {
    name: string;
    label: string;
}

const SwitchControl: React.FC<Props & SwitchProps> = ({ name, label, ...inputProps }) => {
    const { errors, control, getValues } = useFormContext()
    const { enabled: isChecked } = getValues()
    const hasError = Boolean(errors.name)
    const errorText = errors?.name

    return (
        <FormControl error={hasError}>
            <Controller
                name={name}
                as={
                    <FormControlLabel
                        control={
                            <Switch
                                name={name}
                                checked={isChecked}
                                color="secondary"
                                {...inputProps}
                            />
                        }
                        label={label}
                    />
                }
                control={control}
            />
            <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
    )
}
export default SwitchControl
