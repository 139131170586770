import React, { useState } from 'react'
import { useForm, FormContext } from 'react-hook-form'
import * as yup from 'yup'

import { useAuthContext } from 'app/AuthContext'
import { useAppContext } from 'app/AppContext'

import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import { InputField, PrimaryButton } from '@feron/components'

import { authService } from 'library/services/authService'

import Copyright from '../common/Copyright'

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}))

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email('Invalid email')
        .required('Email is required'),
    password: yup
        .string()
        .min(3, 'Password should be at least 3 characters long')
        .required('Password is required')
})

type FormData = {
    email: string
    password: string
}

const LoginForm = () => {
    const classes = useStyles()
    const { showError } = useAppContext()
    const { setSession } = useAuthContext()

    const methods = useForm<FormData>({ validationSchema })

    const [isLoading, setIsLoading] = useState(false)

    const _onSubmit = async (data: any) => {
        try {
            setIsLoading(true)
            const user = await authService.login(data.email, data.password)
            setIsLoading(false)
            setSession(user)
        } catch (error) {
            setIsLoading(false)
            showError(error)
        }
    }

    return (
        <FormContext {...methods}>
            <form className={classes.form} onSubmit={methods.handleSubmit(_onSubmit)}>
                <InputField
                    name="email"
                    label="Email Address"
                    fullWidth
                    autoComplete="email"
                    variant="outlined"
                    margin="normal"
                />
                <InputField
                    name="password"
                    label="Password"
                    fullWidth
                    variant="outlined"
                    type="password"
                    margin="normal"
                />

                <PrimaryButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    isLoading={isLoading}>
                    Sign In
                </PrimaryButton>
                <Box mt={5}>
                    <Copyright />
                </Box>
            </form>
        </FormContext>
    )
}

export default LoginForm
