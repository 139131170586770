import { storage, KEYS } from 'library/storage'

import mapKeys from 'lodash/mapKeys'

import { API } from '../api/api'

import Option from 'library/models/Option'
import isEmpty from 'lodash/isEmpty'

export enum OptionEndpoint {
    COMPANIES = '/companies',
    LANGUAGES = '/languages',
    POSITIONS = '/positions'
}

export interface AllOptions {
    languages: Option[]
    positions: Option[]
    companies: Option[]
}

class OptionsService {
    // async loadOptions() {
    //     let savedPositions = storage.load(KEYS.POSITIONS) ?? []
    //     let savedCompanies = storage.load(KEYS.COMPANIES) ?? []
    //     let savedLanguages = storage.load(KEYS.LANGUAGES) ?? []

    //     const hasSavedOptions = !isEmpty(savedPositions) || !isEmpty(savedCompanies) || !isEmpty(savedLanguages)

    //     if (hasSavedOptions) {

    //     }

    //     const = languages.map((option: any) => new Option(option))
    //     companies.map((option: any) => new Option(option))
    //     positions.map((option: any) => new Option(option))

    //     return { languages, positions, companies }
    // }

    async loadOptions(key: KEYS): Promise<Option[]> {
        let savedOptions = storage.load(key) || []

        const hasSavedOptions = !isEmpty(savedOptions)

        if (hasSavedOptions) {
            return savedOptions.map((option: any) => new Option(option))
        }

        let requestUrl = ''
        switch (key) {
            case KEYS.COMPANIES:
                requestUrl = OptionEndpoint.COMPANIES
                break
            case KEYS.POSITIONS:
                requestUrl = OptionEndpoint.POSITIONS
                break
            case KEYS.LANGUAGES:
                requestUrl = OptionEndpoint.LANGUAGES
                break
            default:
                throw new Error('Invalid key type')
        }

        const params = { limit: 100, offset: 0 }
        const optionsList = await API.get(requestUrl, { params })

        storage.save(key, optionsList)

        return optionsList.map((option: any) => new Option(option))
    }

    async fetchAllOptions(): Promise<AllOptions> {
        const [languages, companies, positions] = await Promise.all([
            this.fetchOptions(OptionEndpoint.LANGUAGES, { limit: 100, offset: 0 }),
            this.fetchOptions(OptionEndpoint.COMPANIES, { limit: 100, offset: 0 }),
            this.fetchOptions(OptionEndpoint.POSITIONS, { limit: 100, offset: 0 })
            // FIXME: Add other options
            // countries, statuses etc.
        ])

        storage.save(KEYS.POSITIONS, positions)
        storage.save(KEYS.COMPANIES, companies)
        storage.save(KEYS.LANGUAGES, languages)
        return { positions, companies, languages }
    }

    async hasOptions() {}

    async fetchOptions(
        endpoint: OptionEndpoint,
        params: { limit: number; offset: number } | any = { limit: 100, offset: 0 }
    ): Promise<Option[]> {
        const data = await Promise.all([
            API.get(endpoint, { params }),
            API.get(endpoint, { params: { enabled: false, ...params } })
        ]).then(([enabledCompanies, disabledCompanies]) =>
            Object.values({
                ...mapKeys(enabledCompanies, 'id'),
                ...mapKeys(disabledCompanies, 'id')
            })
        )
        return data.map((option: any) => new Option(option))
    }

    async updateOption(endpoint: OptionEndpoint, id: string, payload: {}): Promise<any> {
        const requestEndpoint = `${endpoint}/${id}`
        const res = await API.put(requestEndpoint, payload)

        return res.status === 200
    }

    async createOption(endpoint: OptionEndpoint, payload: any): Promise<any> {
        const res = await API.post(endpoint, payload)
        return res.status === 201
    }
}

export const optionsService = new OptionsService()
