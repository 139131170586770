import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import RadioMUI from '@material-ui/core/Radio'
import RadioGroupMUI, { RadioGroupProps } from '@material-ui/core/RadioGroup'

// prettier-ignore
interface Props {
  name: string;
  values: any[];
  keyId?: string;
}
const RadioGroup: React.FC<Props & RadioGroupProps> = ({ name, values, keyId, ...props }) => {
    const { control } = useFormContext()

    return (
        <Controller
            name={name}
            defaultValue={''}
            as={
                <RadioGroupMUI {...props} style={{ justifyContent: 'space-around' }}>
                    {values.map((value: any) => (
                        <RadioMUI color="primary" key={`${keyId}-${name}-${value}`} value={value} />
                    ))}
                </RadioGroupMUI>
            }
            control={control}
        />
    )
}

export default RadioGroup
