import Role from './Role'

class User {
    id: string
    firstName: string
    lastName: string
    email: string
    position: string
    image: string
    level: number
    role: Role

    constructor(user: any) {
        this.id = user.id
        this.firstName = user.firstName
        this.lastName = user.lastName
        this.email = user.email
        this.position = user.position
        this.image = user.profileImage?.url
        this.level = user.level
        this.role = new Role(user.level)
    }

    get fullName() {
        return `${this.firstName} ${this.lastName}`
    }

    get initials() {
        return this.firstName.charAt(0) + this.lastName.charAt(0)
    }
}

export default User
