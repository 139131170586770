import React, { useMemo } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import get from 'lodash/get'

import TextField, { TextFieldProps } from '@material-ui/core/TextField'
// prettier-ignore
interface Props {
    name: string;
    label: string;
}

const InputField: React.FC<Props & TextFieldProps> = ({ name, label, ...inputProps }) => {
    const { errors, control } = useFormContext()

    const error = get(errors, name)
    const hasError = Boolean(error)
    // @ts-ignore
    const errorText = error?.message

    return useMemo(
        () => (
            <Controller
                name={name}
                defaultValue=""
                control={control}
                as={
                    <TextField
                        id={name}
                        autoComplete="off"
                        inputProps={{
                            'aria-autocomplete': 'none'
                        }}
                        label={label}
                        data-cy={`${name}-field`}
                        helperText={errorText}
                        error={hasError}
                        variant="outlined"
                        margin="normal"
                        {...inputProps}
                    />
                }
            />
        ),
        [control, errorText, hasError, inputProps, label, name]
    )
}

export default InputField
