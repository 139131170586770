import { API } from '../api/api'
import { storage, KEYS } from '../storage'

import User from 'library/models/User'
import { queryClient } from 'app/RQueryProvider'

export class AuthService {
    isAuthenticated(): boolean {
        return !!storage.load(KEYS.TOKEN) && !!storage.load(KEYS.USER)
    }

    loadUser() {
        try {
            const userData = storage.load(KEYS.USER)
            const user = new User(userData)
            return user
        } catch (error) {
            storage.remove(KEYS.USER)
            return null
        }
    }

    async login(email: string, password: string) {
        const payload = {
            email,
            password
        }

        const { data } = await API.post('/employees/login', payload)

        if (data && data.token && data.employee) {
            storage.save(KEYS.TOKEN, data.token)
            storage.save(KEYS.USER, data.employee)

            return new User(data.employee)
        }

        return null
    }

    async logout() {
        storage.clear()
        queryClient.clear()
    }
}

export const authService = new AuthService()
