import React, { useRef, useMemo, useCallback } from 'react'

import { usePaginationContext } from './PaginationContext'

import { Box, Select, MenuItem } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Pagination from '@material-ui/lab/Pagination'
import LastPage from '@material-ui/icons/LastPage'

// prettier-ignore
interface Props {
    limit?: number;
    enableLimit?: boolean;
    enableGoTo?: boolean;
}

const TablePagination: React.FC<Props> = ({ enableGoTo = true, enableLimit = true }) => {
    const { params, totalPages, activePage, onChangeLimit, onGoToPage } = usePaginationContext()

    const pageRef = useRef<HTMLInputElement>(null)
    const paginationRef = useRef<HTMLButtonElement>(null)

    const _onLimitChange = useCallback(
        (event: React.ChangeEvent<{ value: any }>) => {
            const { value } = event.target
            const currentLimit = Number.parseInt(value)

            onChangeLimit(currentLimit)
        },
        [onChangeLimit]
    )

    const _onGoToPage = useCallback(() => {
        const page = Number.parseInt(pageRef?.current?.value || '')
        onGoToPage(page)
    }, [onGoToPage])

    const _onPageChange = useCallback(
        (event: any, value: any) => {
            const page = Number.parseInt(value)
            onGoToPage(page)
        },
        [onGoToPage]
    )

    return useMemo(
        () => (
            <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                <Pagination
                    shape="rounded"
                    ref={paginationRef}
                    count={totalPages}
                    page={activePage}
                    onChange={_onPageChange}
                />
                {enableLimit && (
                    <Box marginX={2} display="flex" alignItems="center">
                        <Typography>Items per page: </Typography>
                        <Select
                            labelId="items-per-page-label"
                            id="items-per-page"
                            value={params.limit}
                            label="Items per page"
                            onChange={_onLimitChange}>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </Box>
                )}
                {enableGoTo && (
                    <>
                        <Box marginX={2} display="flex" alignItems="center">
                            <Typography>Go to page</Typography>
                            <input
                                style={{ width: '32px', fontSize: '12pt', textAlign: 'right' }}
                                ref={pageRef}
                                defaultValue={activePage}
                                type="number"
                            />
                        </Box>
                        <IconButton onClick={_onGoToPage} size="small">
                            <LastPage />
                        </IconButton>
                    </>
                )}
            </Box>
        ),
        [
            _onGoToPage,
            _onLimitChange,
            _onPageChange,
            activePage,
            enableGoTo,
            enableLimit,
            params.limit,
            totalPages
        ]
    )
}

export default TablePagination
