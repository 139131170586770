import React, { useMemo } from 'react'
import Button, { ButtonProps } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

interface Props {
    isLoading?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing(2),
            marginRight: theme.spacing(1)
        }
    })
)

const PrimaryButton: React.FC<Props & ButtonProps> = ({ isLoading, ...props }) => {
    const classes = useStyles()
    return useMemo(
        () => (
            <Button
                className={classes.button}
                variant="contained"
                color="primary"
                data-cy={`${props.name}-btn`}
                disabled={isLoading}
                {...props}>
                {isLoading ? (
                    <CircularProgress size={24} style={{ color: 'white' }} />
                ) : (
                    props.children
                )}
            </Button>
        ),
        [classes.button, isLoading, props]
    )
}

export default PrimaryButton
