import { normalizePayloadDate } from './payloadUtils'
import { API } from '../api/api'
import orderBy from 'lodash/orderBy'

import Task, { TaskQueryParams, TaskPayload, TaskStatus } from 'library/models/Task'

export class TaskService {
    async fetchTask(taskId: number | string): Promise<Task> {
        const data = await API.get(`/tasks/${taskId}`)

        return new Task(data)
    }

    async fetchTasks(queryParams: TaskQueryParams): Promise<Task[]> {
        const requestOptions = {
            params: {
                ...queryParams,
                offset: 0,
                limit: 100
            }
        }

        const { data: tasks } = await API.get(`/tasks`, requestOptions)

        return orderBy(tasks, ['updatedAt'], ['desc']).map((task: any) => new Task(task))
    }

    async createTask(task: TaskPayload) {
        const path = `/tasks`

        const createdTask = await API.post(path, normalizePayloadDate(task))

        return createdTask
    }

    async updateTask({ taskId, task }: { taskId: number | string; task: Partial<TaskPayload> }) {
        const path = `/tasks/${taskId}`

        const updatedTask = await API.put(path, normalizePayloadDate(task))

        return updatedTask
    }

    async taskCount(assigneeId: string): Promise<number> {
        const path = `/tasks`

        const params = {
            assigneeId,
            offset: 0,
            limit: 1,
            statusId: TaskStatus.TODO
        }

        const { data } = await API.getFull(path, {
            params
        })

        return data.totalCount
    }

    async deleteTask(taskId: number | string) {
        const path = `/tasks/${taskId}`
        const res = await API.delete(path)
        return res.status === 200
    }
}

export const tasksService = new TaskService()
