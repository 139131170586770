import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import get from 'lodash/get'

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import MUISelect, { SelectProps } from '@material-ui/core/Select'
import MUIMenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            width: '100%',
            minWidth: 140
        }
    })
)
// prettier-ignore
interface Option {
    name: string;
    value: string;
}

// prettier-ignore
interface Props {
    name: string;
    label: string;
    options: any

}

const Select: React.FC<Props & SelectProps> = ({ name, label, options, ...inputProps }) => {
    const classes = useStyles()

    const inputLabel = useRef<HTMLLabelElement>(null)

    const [labelWidth, setLabelWidth] = useState(0)

    const { control, errors } = useFormContext()

    useEffect(() => {
        setLabelWidth(inputLabel?.current?.offsetWidth || 0)
    }, [])

    const error = get(errors, name)
    const hasError = Boolean(error)
    // @ts-ignore
    const errorText = error?.message

    return useMemo(
        () => (
            <FormControl variant="outlined" className={classes.formControl} error={hasError}>
                <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
                    {label}
                </InputLabel>
                <Controller
                    name={name}
                    defaultValue={''}
                    control={control}
                    as={
                        <MUISelect
                            id={name}
                            name={name}
                            defaultValue={''}
                            labelWidth={labelWidth}
                            {...inputProps}>
                            <MUIMenuItem value="">None</MUIMenuItem>
                            {options?.map((option: Option) => (
                                <MUIMenuItem key={option.name} value={option.value}>
                                    {option.name}
                                </MUIMenuItem>
                            ))}
                        </MUISelect>
                    }
                />
                {errorText && <FormHelperText>{errorText}</FormHelperText>}
            </FormControl>
        ),
        [
            classes.formControl,
            control,
            errorText,
            hasError,
            inputProps,
            label,
            labelWidth,
            name,
            options
        ]
    )
}

export default Select
