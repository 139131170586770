import React from 'react'

import NotificationProvider from 'app/NotificationProvider'
import TopAppBar from './TopAppBar'

interface PropTypes {
    open: boolean
    handleDrawerOpen: any
}

const Header: React.FC<PropTypes> = props => (
    <NotificationProvider>
        <TopAppBar {...props} />
    </NotificationProvider>
)

export default Header
