import React, { useState, useCallback } from 'react'

import { paginationInitialValues, PaginationContext, PaginationParams } from './PaginationContext'

const PaginationProvider: React.FC = ({ children }) => {
    const [activePage, setActivePage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalResults, setTotalResults] = useState(0)

    const [params, setParams] = useState<PaginationParams>(paginationInitialValues)

    const onChangeLimit = useCallback((limit: number) => {
        setParams({ offset: 0, limit })
        setActivePage(1)
    }, [])

    const onChangeParams = useCallback((params: PaginationParams) => {
        setParams(params)
    }, [])

    const onGoToPage = useCallback(
        (page: number) => {
            const offset = (page - 1) * params.limit
            setParams(prevParams => ({ ...prevParams, offset }))
            setActivePage(page)
        },
        [params.limit]
    )

    const setPagesCount = useCallback(
        (resultsCount: number, pagesCount?: number) => {
            const pageCount = pagesCount || +resultsCount / params.limit

            setTotalPages(Math.ceil(pageCount))
            setTotalResults(resultsCount)
        },
        [params.limit]
    )

    const providerValue = {
        params,
        totalPages,
        activePage,
        totalResults,
        setPagesCount,
        onChangeParams,
        onChangeLimit,
        onGoToPage
    }

    return <PaginationContext.Provider value={providerValue}>{children}</PaginationContext.Provider>
}

export default PaginationProvider
