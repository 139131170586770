import React, { useContext } from 'react'
import mapKeys from 'lodash/mapKeys'

export interface OptionsContextProps {
    languages: any[]
    companies: any[]
    positions: any[]
    loadOptions: (forceFetch: boolean) => void
}

const OptionsContext = React.createContext<OptionsContextProps>({
    languages: [],
    companies: [],
    positions: [],
    loadOptions: (_forceFetch: boolean) => {
        throw new Error('loadOptions() not implemented')
    }
})

export const useOptions = (): OptionsContextProps => {
    return useContext(OptionsContext)
}

export const useLanguageOptions = () => {
    const { languages } = useContext(OptionsContext)
    return languages
}
export const useCompaniesOptions = () => {
    const { companies } = useContext(OptionsContext)
    return companies
}

export const useMappedCompaniesOptions = () => {
    const { companies } = useContext(OptionsContext)
    return mapKeys(companies, 'value')
}

export const useMappedPositionOptions = () => {
    const { positions } = useContext(OptionsContext)
    return mapKeys(positions, 'value')
}

export const usePositionsOptions = () => {
    const { positions } = useContext(OptionsContext)
    return positions
}

export default OptionsContext
