import strings from './strings'
import colors from './colors'
import dimens from './dimens'
import arrays from './arrays'
import images from './images'

const R = {
    strings,
    colors,
    dimens,
    images,
    arrays
}

export default R
