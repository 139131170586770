import React from 'react'
import { isDev } from 'config/constants'

import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClient, QueryClientProvider } from 'react-query'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
            refetchOnMount: true
        }
    }
})

interface Props {}

export const RQueryProvider: React.FC<Props> = ({ children }) => (
    <QueryClientProvider client={queryClient}>
        {isDev && <ReactQueryDevtools initialIsOpen={false} />}
        {children}
    </QueryClientProvider>
)
