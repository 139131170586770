import React from 'react'
import { hot } from 'react-hot-loader/root'

import { useAuthContext } from './AuthContext'

import AppProvider from './AppProvider'
import OptionsProvider from './OptionsProvider'
import AuthProvider from './AuthProvider'
import { RQueryProvider } from './RQueryProvider'

import AuthMain from './main/AuthMain'
import AdminMain from './main/AdminMain'

const AppMain: React.FC = () => {
    const { authenticated } = useAuthContext()
    return authenticated ? (
        <RQueryProvider>
            <OptionsProvider>
                <AdminMain />
            </OptionsProvider>
        </RQueryProvider>
    ) : (
        <AuthMain />
    )
}

const App: React.FC = () => (
    <AppProvider>
        <AuthProvider>
            <AppMain />
        </AuthProvider>
    </AppProvider>
)

export default process.env.NODE_ENV === 'development' ? hot(App) : App
