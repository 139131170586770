import { storage, KEYS } from 'library/storage'

export const authHeader = () => {
    const token = storage.load(KEYS.TOKEN)

    const commonHeaders = {
        'Content-Type': 'application/json'
    }

    return token
        ? {
              ...commonHeaders,
              Authorization: `JWT ${token}`
          }
        : commonHeaders
}
