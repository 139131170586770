import { ROLE } from 'library/models/Role'

export enum Permission {
    VIEW = 'candidate:view',
    VIEW_PERSONAL = 'candidate:view:personal',
    MANAGE_CANDIDATE = 'candidate:manage',
    CREATE_CANDIDATE = 'candidates:create',
    DELETE_CANDIDATE = 'candidates:delete',
    MANAGE_EMPLOYEES = 'employees:manage',
    MANAGE_OPTIONS = 'options:manage',
    MANAGE_JOB_APPLICATIONS = 'applications:edit',
    DELETE_JOB_APPLICATION = 'applications:delete',
    MANAGE_INVOICING = 'invoicing:manage',
    TASKS_MANAGE = 'tasks:manage',
    TASKS_ASSIGN = 'tasks:assign',
    FILTER_USER_TYPE = 'filter:user-type',
    ALL = 'everything'
}

const rules = {
    [ROLE.GUEST]: {
        static: [Permission.VIEW, Permission.TASKS_MANAGE]
    },
    [ROLE.EMPLOYEE]: {
        static: [Permission.VIEW, Permission.VIEW_PERSONAL, Permission.TASKS_MANAGE]
    },
    [ROLE.RECRUITER]: {
        static: [
            Permission.VIEW,
            Permission.VIEW_PERSONAL,
            Permission.CREATE_CANDIDATE,
            Permission.MANAGE_CANDIDATE,
            Permission.TASKS_MANAGE,
            Permission.TASKS_ASSIGN,
            Permission.MANAGE_JOB_APPLICATIONS,
            Permission.FILTER_USER_TYPE
        ],
        dynamic: {}
    },
    [ROLE.RECRUITING_MANAGER]: {
        static: [
            Permission.VIEW,
            Permission.VIEW_PERSONAL,
            Permission.CREATE_CANDIDATE,
            Permission.MANAGE_CANDIDATE,
            Permission.DELETE_CANDIDATE,
            Permission.MANAGE_INVOICING,
            Permission.TASKS_MANAGE,
            Permission.TASKS_ASSIGN,
            Permission.MANAGE_JOB_APPLICATIONS,
            Permission.DELETE_JOB_APPLICATION,
            Permission.FILTER_USER_TYPE
        ],
        dynamic: {}
    },
    [ROLE.GENERAL_MANAGER]: {
        static: [
            Permission.VIEW,
            Permission.VIEW_PERSONAL,
            Permission.CREATE_CANDIDATE,
            Permission.MANAGE_CANDIDATE,
            Permission.DELETE_CANDIDATE,
            Permission.MANAGE_INVOICING,
            Permission.TASKS_MANAGE,
            Permission.MANAGE_OPTIONS,
            Permission.MANAGE_EMPLOYEES,
            Permission.TASKS_ASSIGN,
            Permission.MANAGE_JOB_APPLICATIONS,
            Permission.DELETE_JOB_APPLICATION,
            Permission.FILTER_USER_TYPE,
            Permission.ALL
        ]
    }
}

export default rules
