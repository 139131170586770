import React from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import LoginForm from './LoginForm'

import R from 'res/R'
import { Box } from '@material-ui/core'

const imageStyle = {
    backgroundImage: `url(${R.images.loginSplash})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
}

const LoginPage = () => (
    <Grid container component="main" style={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} style={imageStyle} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box marginX={4} marginY={8}>
                <img src={R.images.feronLogo} alt="Feron logo" style={{ width: '100%' }} />
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <LoginForm />
            </Box>
        </Grid>
    </Grid>
)

export default LoginPage
