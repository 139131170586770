import React, { useContext, Dispatch } from 'react'

type AppContextType = {
    isLoading: boolean // show backdrop loader
    setBackdropLoading: Dispatch<any>
    showError: (error: string) => void
}

export const AppContext = React.createContext<AppContextType>({
    isLoading: true, // show backdrop loader
    setBackdropLoading: () => {
        throw new Error('setBackdropLoading() not implemented')
    },
    showError: (_error: string) => {
        throw new Error('setBackdropLoading() not implemented')
    }
})

export const useAppContext = (): AppContextType => {
    return useContext(AppContext)
}
