import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'

import { AppContext } from './AppContext'

import { Toast } from '@feron/components'

const AppProvider = (props: any) => {
    const [error, setError] = useState<string>('')
    const [isLoading, setBackdropLoading] = useState<boolean>(false)

    const showError = (error: string = '') => {
        setError(error)
        setTimeout(() => {
            setError('')
        }, 3000)
    }

    const _handleErrorClose = () => setError('')

    const shouldDisplayToast = !isEmpty(error)

    const providerValue = {
        isLoading,
        setBackdropLoading,
        showError
    }

    return (
        <AppContext.Provider value={providerValue}>
            <Toast
                open={shouldDisplayToast}
                onClose={_handleErrorClose}
                severity="error"
                message={error}
            />
            {/* <ErrorBoundary>{props.children}</ErrorBoundary> */}
            {props.children}
        </AppContext.Provider>
    )
}

export default AppProvider
