import { useQuery } from 'react-query'

import { useAuthContext } from 'app/AuthContext'

import { tasksService } from 'library/services/tasksService'
import { TaskStatus } from 'library/models/Task'

const TASKS_FETCH_DELAY = 1000 * 1 * 30 // SECONDS

export function useTasks(status: TaskStatus, created = false) {
    const { user } = useAuthContext()

    const params = {
        ...(created ? { creatorId: user.id } : { assigneeId: user.id }),
        statusId: status
    }

    return useQuery(`tasks-${status}`, () => tasksService.fetchTasks(params), {
        refetchOnMount: 'always',
        refetchOnWindowFocus: 'always'
    })
}

export function useTaskCount() {
    const { user } = useAuthContext()
    return useQuery(`tasks-count`, () => tasksService.taskCount(user.id), {
        refetchInterval: TASKS_FETCH_DELAY,
        refetchIntervalInBackground: true,
        refetchOnWindowFocus: 'always'
    })
}
