import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import get from 'lodash/get'

import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker as BaseDatePicker,
    DatePickerProps
} from '@material-ui/pickers'

// prettier-ignore
interface Props {
    name: string;
    label: string;
}

const DatePicker: React.FC<Props & Partial<DatePickerProps>> = props => {
    const { control, errors } = useFormContext()

    const error = get(errors, props.name)
    const hasError = Boolean(error)
    //@ts-ignore
    const errorText = error?.message

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
                control={control}
                as={BaseDatePicker}
                error={hasError}
                helperText={errorText}
                format="dd/MM/yyyy"
                {...props}
            />
        </MuiPickersUtilsProvider>
    )
}

export default DatePicker
