import React from 'react'
import { NavLink as Link } from 'react-router-dom'

import { Permission } from 'config/rbacRules'
import { LINK_ACTIVE_RECRUITMENT, LINK_PROCEDURE } from 'config/constants'

import { Divider } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined'
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded'
import LanguageIcon from '@material-ui/icons/Language'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import WorkOutlineIcon from '@material-ui/icons/WorkOutline'
import BusinessIcon from '@material-ui/icons/Business'
import ReceiptIcon from '@material-ui/icons/Receipt'
import ContactlessIcon from '@material-ui/icons/Contactless'

import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

import Can from 'app/common/Can'

const NavigationList: React.FC<{}> = () => (
    <List>
        <ListItem button component={Link} activeClassName="Mui-selected" to="/dashboard">
            <ListItemIcon>
                <DashboardOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button component={Link} activeClassName="Mui-selected" to="/candidates">
            <ListItemIcon>
                <PeopleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Candidates" />
        </ListItem>
        <Can perform={Permission.TASKS_MANAGE}>
            <ListItem button component={Link} activeClassName="Mui-selected" to="/tasks">
                <ListItemIcon>
                    <FormatListBulletedIcon />
                </ListItemIcon>
                <ListItemText primary="My Tasks" />
            </ListItem>
        </Can>
        <ListItem button component={Link} activeClassName="Mui-selected" to="/profile">
            <ListItemIcon>
                <PersonOutlineRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="Profile" />
        </ListItem>
        <Divider />
        <Can perform={Permission.MANAGE_EMPLOYEES}>
            <ListItem button component={Link} activeClassName="Mui-selected" to="/employees">
                <ListItemIcon>
                    <GroupWorkIcon />
                </ListItemIcon>
                <ListItemText primary="Employees" />
            </ListItem>
        </Can>
        <Can perform={Permission.ALL}>
            <ListItem button component={Link} activeClassName="Mui-selected" to="/invoicing">
                <ListItemIcon>
                    <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary="Invoicing" />
            </ListItem>
            <Divider />
        </Can>
        <Can perform={Permission.MANAGE_OPTIONS}>
            <ListItem button component={Link} activeClassName="Mui-selected" to="/positions">
                <ListItemIcon>
                    <WorkOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="Positions" />
            </ListItem>
            <ListItem button component={Link} activeClassName="Mui-selected" to="/companies">
                <ListItemIcon>
                    <BusinessIcon />
                </ListItemIcon>
                <ListItemText primary="Companies" />
            </ListItem>
            <ListItem button component={Link} activeClassName="Mui-selected" to="/statuses">
                <ListItemIcon>
                    <ContactlessIcon />
                </ListItemIcon>
                <ListItemText primary="Statuses" />
            </ListItem>
            <ListItem button component={Link} activeClassName="Mui-selected" to="/languages">
                <ListItemIcon>
                    <LanguageIcon />
                </ListItemIcon>
                <ListItemText primary="Languages" />
            </ListItem>
        </Can>
        <Can perform={Permission.VIEW_PERSONAL}>
            <Divider />
            <ListItem
                button
                onClick={() => {
                    window.open(LINK_PROCEDURE, '_blank')
                }}>
                <ListItemIcon>
                    <OpenInNewIcon />
                </ListItemIcon>
                <ListItemText primary="Procedure" />
            </ListItem>
            <ListItem
                button
                onClick={() => {
                    window.open(LINK_ACTIVE_RECRUITMENT, '_blank')
                }}>
                <ListItemIcon>
                    <OpenInNewIcon />
                </ListItemIcon>
                <ListItemText primary="Aktivne regrutacije" />
            </ListItem>
        </Can>
    </List>
)

export default React.memo(NavigationList)
