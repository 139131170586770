import React, { useState, useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'

import {
    NotificationContext,
    NotificationType,
    NotificationDuration,
    toastDefaults
} from './NotificationContext'

import BackdropLoader from 'view/components/BackdropLoader'

import { Toast } from '@feron/components'

type ToastInfo = {
    message: string
    type: NotificationType
    duration: NotificationDuration
}

const NotificationProvider = (props: any) => {
    const [isLoading, showLoading] = useState<boolean>(false)
    const [toast, setToastInfo] = useState<ToastInfo>(toastDefaults)

    const showToast = useCallback(
        (
            message: string,
            type: NotificationType = NotificationType.INFO,
            duration: NotificationDuration = NotificationDuration.LONG
        ) => {
            setToastInfo({ message, type, duration })

            setTimeout(() => {
                setToastInfo(toastDefaults)
            }, 3000)
        },
        []
    )

    const _handleErrorClose = () => setToastInfo(toastDefaults)

    const shouldDisplayToast = !isEmpty(toast.message)

    const providerValue = {
        isLoading,
        showLoading,
        showToast
    }

    return (
        <NotificationContext.Provider value={providerValue}>
            <Toast
                open={shouldDisplayToast}
                onClose={_handleErrorClose}
                severity={toast.type}
                message={toast.message}
            />
            {/* <ErrorBoundary>{props.children}</ErrorBoundary> */}
            {props.children}
            <BackdropLoader isLoading={isLoading} />
        </NotificationContext.Provider>
    )
}

export default NotificationProvider
