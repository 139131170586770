import React, { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'

import MainLayout from './MainLayout'
import Loader from 'view/components/Loader'

const DashboardPage = React.lazy(() => import('app/dashboard/DashboardPage'))
const CandidatesPage = React.lazy(() => import('app/candidates/CandidatesPage'))
const ProfilePage = React.lazy(() => import('app/profile/ProfilePage'))
const EmployeesPage = React.lazy(() => import('app/employees/EmployeesPage'))
const PositionListPage = React.lazy(() => import('app/options/positions/PositionListPage'))
const CompaniesListPage = React.lazy(() => import('app/options/companies/CompaniesListPage'))
const StatusesListPage = React.lazy(() => import('app/options/statuses/StatusesListPage'))
const LanguagesListPage = React.lazy(() => import('app/options/languages/LanguagesListPage'))
const EmployeePage = React.lazy(() => import('app/employee-details/EmployeePage'))
const AddEmployeePage = React.lazy(() => import('app/employee-details/AddEmployeePage'))
const InvoicingPage = React.lazy(() => import('app/invoicing/InvoicingPage'))
const TasksPage = React.lazy(() => import('app/my-tasks/TasksPage'))

const MainContent: React.FC<{}> = () => (
    <MainLayout>
        <Suspense fallback={<Loader />}>
            <Switch>
                <Route exact path="/employees/new" component={AddEmployeePage} />
                <Route path="/employees/:employeeId" component={EmployeePage} />
                <Route path="/employees" component={EmployeesPage} />
                <Route path="/tasks" component={TasksPage} />
                <Route path="/invoicing" component={InvoicingPage} />
                <Route path="/languages" component={LanguagesListPage} />
                <Route path="/companies" component={CompaniesListPage} />
                <Route path="/statuses" component={StatusesListPage} />
                <Route path="/positions" component={PositionListPage} />
                <Route path="/profile" component={ProfilePage} />
                <Route path="/candidates" component={CandidatesPage} />
                <Route path="/dashboard" component={DashboardPage} />
                <Route exact path="/" component={DashboardPage} />
            </Switch>
        </Suspense>
    </MainLayout>
)

export default MainContent
