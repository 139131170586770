import React, { useContext } from 'react'

import User from 'library/models/User'

type AuthContextType = {
    authenticated: boolean // to check if authenticated or not
    user: User | any
    setSession: (user: any) => void
    isMe: (userId: number | string) => boolean
    logout: () => void // logout the user
}

export const AuthContext = React.createContext<AuthContextType>({
    authenticated: false, // to check if authenticated or not
    user: null,
    setSession: (_user: User) => {
        throw new Error('setSession() not implemented')
    },
    isMe: (_userId: number | string) => {
        throw new Error('isMe() not implemented')
    },
    logout: () => {
        throw new Error('logout() not implemented')
    }
})

export const useAuthContext = (): AuthContextType => {
    return useContext(AuthContext)
}
