import React from 'react'

import { useTaskCount } from 'app/my-tasks/hooks/useTasks'

import Badge from '@material-ui/core/Badge'
import NotificationsIcon from '@material-ui/icons/Notifications'

interface Props {}

const TasksCounterBadge = (props: Props) => {
    const { data: taskCount } = useTaskCount()
    return (
        <Badge badgeContent={taskCount} color="secondary">
            <NotificationsIcon />
        </Badge>
    )
}

export default React.memo(TasksCounterBadge)
