import React from 'react'
import { useHistory } from 'react-router'
import clsx from 'clsx'

import { useAuthContext } from 'app/AuthContext'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { deepOrange } from '@material-ui/core/colors'

import { ImageAvatar } from '@feron/components'

import TasksCounterBadge from './TasksCounterBadge'
import AppMenu from './AppMenu'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
    toolbar: {
        paddingRight: 24 // keep right padding when drawer closed
    },

    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: 36
    },
    menuButtonHidden: {
        display: 'none'
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500]
    },
    title: {
        flexGrow: 1
    }
}))

type PropTypes = {
    open: boolean
    handleDrawerOpen: any
}

const TopAppBar: React.FC<PropTypes> = ({ open, handleDrawerOpen }) => {
    const { user } = useAuthContext()

    const classes = useStyles()
    const history = useHistory()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const menuId = 'primary-account-menu'

    return (
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
                    <MenuIcon />
                </IconButton>
                <Typography
                    noWrap
                    component="h1"
                    variant="h6"
                    color="inherit"
                    className={classes.title}>
                    Feron
                </Typography>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={() => {
                        history.push('/tasks')
                    }}>
                    <TasksCounterBadge />
                </IconButton>

                <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit">
                    <ImageAvatar
                        size="medium"
                        alt={user.fullName}
                        src={user.profileImage}
                        className={classes.orange}
                    />
                </IconButton>
            </Toolbar>
            <AppMenu menuId={menuId} menuAnchor={anchorEl} onMenuClose={handleMenuClose} />
        </AppBar>
    )
}

export default React.memo(TopAppBar)
