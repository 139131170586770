import React from 'react'
import { Switch, Route, Redirect } from 'react-router'

import LoginPage from 'app/login/LoginPage'

const AuthMain = () => (
    <Switch>
        <Route exact path="/login">
            <LoginPage />
        </Route>
        <Redirect to="/login" />
    </Switch>
)

export default AuthMain
