import React from 'react'

import { useAuthContext } from 'app/AuthContext'

import rules, { Permission } from 'config/rbacRules'

const check = (rules: any, role: number, action: any, data: any) => {
    const permissions = rules[role]
    if (!permissions) {
        // role is not present in the rules
        return false
    }

    const staticPermissions = permissions.static

    if (staticPermissions && staticPermissions.includes(action)) {
        // static rule not provided for action
        return true
    }

    const dynamicPermissions = permissions.dynamic

    if (dynamicPermissions) {
        const permissionCondition = dynamicPermissions[action]
        if (!permissionCondition) {
            // dynamic rule not provided for action
            return false
        }

        return permissionCondition(data)
    }
    return false
}

interface Props {
    perform: Permission
    data?: any
    role?: any
}

const Can: React.FC<Props> = props => {
    const { user } = useAuthContext()

    const { role = user.role.id, perform, data, children } = props

    return check(rules, role, perform, data) ? <>{children}</> : null
}

export const isCandidate = (userType: string) => {
    return userType.toUpperCase() === 'CANDIDATE'
}

export const isEmployee = (userType: string) => {
    return userType.toUpperCase() === 'EMPLOYEE'
}

export default Can
