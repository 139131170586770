// @ts-ignore
import React, { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import get from 'lodash/get'

import Box from '@material-ui/core/Box'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select, { SelectProps } from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'

// prettier-ignore
interface Props {
    name: string;
    label: string;
    options: { name: string; value: string }[];
}

const MultiSelect: React.FC<Props & SelectProps> = ({
    name,
    label,
    options = [],
    ...selectProps
}) => {
    const { control, setValue, errors } = useFormContext()

    const _onHandleChange = useCallback(
        (event: any) => {
            setValue(name, event.target.value)
        },
        [name, setValue]
    )

    const error = get(errors, name)
    // @ts-ignore
    const errorText = error?.message

    return React.useMemo(
        () => (
            <Controller
                name={name}
                defaultValue=""
                as={
                    <FormControl variant="outlined" fullWidth error={Boolean(error)}>
                        <InputLabel id="mutiple-chip-label">{label}</InputLabel>
                        <Select
                            fullWidth
                            multiple
                            name={name}
                            labelId="mutiple-chip-label"
                            id="mutiple-chip"
                            defaultValue={[]}
                            onChange={_onHandleChange}
                            control={control}
                            input={<Input id="select-multiple-chip" />}
                            {...selectProps}
                            renderValue={(selectedOptions: unknown) => (
                                <Box display="flex" flexWrap="wrap">
                                    {(selectedOptions || []).map(selectedOption => (
                                        <Box m={1} key={selectedOption.value}>
                                            <Chip label={selectedOption.name} />
                                        </Box>
                                    ))}
                                </Box>
                            )}>
                            {options.map((option: any) => (
                                <MenuItem key={option.value} value={option}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {errorText && <FormHelperText>{errorText}</FormHelperText>}
                    </FormControl>
                }
            />
        ),
        [_onHandleChange, control, error, errorText, label, name, options, selectProps]
    )
}

export default MultiSelect
