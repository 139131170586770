export enum ROLE {
    GUEST = 1,
    EMPLOYEE = 2,
    RECRUITER = 3,
    RECRUITING_MANAGER = 4,
    GENERAL_MANAGER = 5
}

class Role {
    id: number

    constructor(roleId: any) {
        this.id = Number.parseInt(roleId)
    }

    get isGeneralManager(): boolean {
        return this.id === ROLE.GENERAL_MANAGER
    }

    get isRecruitingManager(): boolean {
        return this.id === ROLE.RECRUITING_MANAGER
    }

    get isRecruiter(): boolean {
        return this.id === ROLE.RECRUITER
    }

    get isEmployee(): boolean {
        return this.id === ROLE.EMPLOYEE
    }

    get canEdit(): boolean {
        return (
            this.id === ROLE.RECRUITER ||
            this.id === ROLE.RECRUITING_MANAGER ||
            this.id === ROLE.GENERAL_MANAGER
        )
    }

    get canAssign(): boolean {
        return this.id === ROLE.RECRUITING_MANAGER || this.id === ROLE.GENERAL_MANAGER
    }

    get isGuest(): boolean {
        return this.id === ROLE.GUEST
    }

    get displayName(): string {
        switch (this.id) {
            case ROLE.GENERAL_MANAGER:
                return `General Manager (${this.id})`
            case ROLE.RECRUITING_MANAGER:
                return `Recruiting Manager (${this.id})`
            case ROLE.RECRUITER:
                return `Recruiter (${this.id})`
            case ROLE.EMPLOYEE:
                return `Employee (${this.id})`
            default:
                return `Guest (${this.id})`
        }
    }
}

export default Role
