import dayjs from 'dayjs'
import startCase from 'lodash/startCase'
import Role from './Role'

class Employee {
    id: number
    firstName: string
    lastName: string
    email: string
    level: number
    password: string
    createdAt: string
    updatedAt: string
    enabled: boolean
    profileImage: string
    profileImageId: string

    constructor(data: any) {
        this.id = data.id
        this.firstName = data.firstName
        this.lastName = data.lastName
        this.email = data.email
        this.level = data.level
        this.password = data.password
        this.createdAt = data.createdAt
        this.updatedAt = data.updatedAt
        this.profileImage = data.profileImage?.url
        this.profileImageId = data.profileImageId
        this.enabled = data.enabled
    }

    get fullName() {
        return startCase(`${this.firstName} ${this.lastName}`.toLowerCase())
    }

    get initials() {
        return this.firstName.charAt(0) + this.lastName.charAt(0)
    }

    get role() {
        return new Role(this.level)
    }

    get dateCreated() {
        return dayjs(this.createdAt).format('DD/MM/YYYY')
    }

    get dateEdited() {
        return dayjs(this.updatedAt).format(`DD/MM/YYYY | HH:mm`)
    }
}

export default Employee
