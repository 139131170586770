import React, { useState, useEffect, useRef } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import get from 'lodash/get'

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import MUISelect, { SelectProps } from '@material-ui/core/Select'
import Box from '@material-ui/core/Box'
import MUIMenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

import countries from './countries'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            width: '100%',
            minWidth: 140
        }
    })
)

// prettier-ignore
interface Option {
    name: string;
    value: string;
}

// prettier-ignore
interface Props {
    name: string;
    label: string;
    options?: Option[];
}

const SelectCountry: React.FC<Props & SelectProps> = ({
    name,
    label,
    options = countries,
    ...inputProps
}) => {
    const classes = useStyles()

    const [labelWidth, setLabelWidth] = useState(0)

    const inputLabel = useRef<HTMLLabelElement>(null)

    const { control, errors } = useFormContext<Option>()

    useEffect(() => {
        setLabelWidth(inputLabel?.current?.offsetWidth || 0)
    }, [])

    const error = get(errors, name)
    const hasError = Boolean(error)
    const errorText = error?.message

    return React.useMemo(
        () => (
            <FormControl variant="outlined" className={classes.formControl} error={hasError}>
                <InputLabel ref={inputLabel} id={`${name}-select-outlined-label`}>
                    {label}
                </InputLabel>
                <Controller
                    name={name}
                    control={control}
                    as={
                        <MUISelect id={name} name={name} labelWidth={labelWidth} {...inputProps}>
                            <MUIMenuItem value="">-- None --</MUIMenuItem>
                            {options.map((option: Option) => (
                                <MUIMenuItem
                                    key={option.name}
                                    value={option.value}
                                    disabled={option.value === '-'}>
                                    <Box display="flex" width="90%" alignItems="center">
                                        {option.value.length >= 2 && (
                                            <img
                                                style={{ marginRight: '10px' }}
                                                src={`https://flagcdn.com/28x21/${option.value}.png`.toLowerCase()}
                                                alt={option.value}
                                            />
                                        )}
                                        <span> </span>
                                        {option.name}
                                    </Box>
                                </MUIMenuItem>
                            ))}
                        </MUISelect>
                    }
                />
                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>
        ),
        [
            classes.formControl,
            control,
            errorText,
            hasError,
            labelWidth,
            inputProps,
            label,
            name,
            options
        ]
    )
}

export default SelectCountry
