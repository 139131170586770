import React from 'react'
import clsx from 'clsx'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Avatar, { AvatarProps } from '@material-ui/core/Avatar'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3)
        },
        medium: {
            width: theme.spacing(4),
            height: theme.spacing(4)
        }
    })
)

interface Props {
    size?: 'small' | 'medium'
}

const ImageAvatar: React.FC<Props & AvatarProps> = ({
    size,
    children,
    className,
    ...avatarProps
}) => {
    const classes = useStyles()
    let clazz = undefined

    switch (size) {
        case 'medium':
            clazz = classes.medium
            break
        case 'small':
            clazz = classes.small
            break
        default:
            clazz = undefined
    }

    return (
        <Avatar className={clsx(clazz, className)} {...avatarProps}>
            {children}
        </Avatar>
    )
}

export default React.memo(ImageAvatar)
