import React from 'react'
import { useHistory } from 'react-router'

import { Box, Divider, Typography } from '@material-ui/core'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import ExitAppIcon from '@material-ui/icons/ExitToApp'
import CachedIcon from '@material-ui/icons/Cached'
import AccountCircle from '@material-ui/icons/AccountCircle'

import { useAuthContext } from 'app/AuthContext'

interface Props {
    menuId: string
    menuAnchor: null | HTMLElement
    onMenuClose: () => void
}

const AppMenu: React.FC<Props> = ({ menuId = 'menu', menuAnchor, onMenuClose }) => {
    const history = useHistory()
    const { logout, user } = useAuthContext()

    const isMenuOpen = Boolean(menuAnchor)

    const handleProfileClick = () => {
        history.push('/profile')
        onMenuClose()
    }

    const handleReloadClick = () => {
        window.location.reload(true)
        onMenuClose()
    }

    return (
        <Menu
            anchorEl={menuAnchor}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'center', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={onMenuClose}>
            <MenuItem disabled>
                <Box>
                    <Typography variant="button">{user.fullName}</Typography>
                </Box>
            </MenuItem>
            <MenuItem onClick={handleProfileClick}>
                <AccountCircle fontSize="default" />
                <Box mx={2}>
                    <Typography variant="inherit">Profile</Typography>
                </Box>
            </MenuItem>
            <MenuItem onClick={handleReloadClick}>
                <CachedIcon />
                <Box mx={2}>
                    <Typography variant="inherit">Update</Typography>
                </Box>
            </MenuItem>
            <Divider />
            <MenuItem onClick={logout}>
                <ExitAppIcon color="secondary" />
                <Box mx={2}>
                    <Typography variant="inherit">Logout</Typography>
                </Box>
            </MenuItem>
        </Menu>
    )
}

export default React.memo(AppMenu)
