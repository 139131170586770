import dayjs from 'dayjs'

export const formatPayloadDate = (
    date: string | Date | null,
    dateFormat: string = 'YYYY-MM-DD'
): string | null => {
    return !!date ? dayjs(date || undefined).format(dateFormat) : null
}

export const normalizePayloadDate = (payload: any, dateFormat: string = 'YYYY-MM-DD'): any => {
    const normalizedPayloadValues = Object.entries(payload).map(([key, value]) => {
        if (key.toLowerCase().includes('date')) {
            const dateValue = dayjs(value as any).format(dateFormat)

            return [key, dateValue]
        } else {
            return [key, value]
        }
    })

    return Object.fromEntries(normalizedPayloadValues)
}
