import dayjs from 'dayjs'

class Option {
    id: string
    name: string
    enabled: boolean
    modified: string
    value: string
    hired?: boolean
    kind?: string

    constructor(position: any) {
        this.id = position.id
        this.name = position.name
        this.enabled = position.enabled
        this.modified = position.updatedAt
        this.value = position.id
        this.hired = position.hired
        this.kind = position.kind
    }

    get dateEdited() {
        return dayjs(this.modified).format('DD/MM/YYYY')
    }
}

export class Language extends Option {}
export class Position extends Option {}
export class Company extends Option {}

export default Option
