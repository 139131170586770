import React, { useState, useCallback } from 'react'

import { useAppContext } from 'app/AppContext'

import { Box } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'

import Sidebar from 'app/sidebar/Sidebar'
import Header from 'app/header/Header'

import BackdropLoader from 'view/components/BackdropLoader'

import MainContent from './MainContent'

const AdminMain = () => {
    const { isLoading } = useAppContext()
    const [open, setOpen] = useState(true)

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <Box display="flex">
            <CssBaseline />
            <Header handleDrawerOpen={handleDrawerOpen} open={open} />
            <Sidebar handleDrawerClose={handleDrawerClose} open={open} />
            <MainContent />
            <BackdropLoader isLoading={isLoading} />
        </Box>
    )
}

export default AdminMain
