import dayjs from 'dayjs'
import Employee from './Employee'

export type TaskQueryParams = {
    query?: string
    dueDateFrom?: string | Date
    dueDateTo?: string | Date
    assigneeId?: number
    creatorId?: number
    statusId?: number
}

export type TaskPayload = {
    title: string
    description: string
    dueDate: Date | null
    assigneeId: number | string
    statusId: number | string | TaskStatus
}

export enum TaskStatus {
    TODO = 1,
    IN_PROGRESS = 11,
    DONE = 21,
    BLOCKED = 31
}

class Task {
    id: number
    title: string
    description: boolean
    dueDate: string
    assigneeId: string
    statusId: string
    creatorId: string
    creator: Employee
    assignee: Employee
    createdAt: string
    updatedAt: string

    constructor(data: any) {
        this.id = data.id
        this.title = data.title
        this.description = data.description
        this.dueDate = data.dueDate
        this.assignee = new Employee(data.assignee)
        this.assigneeId = data.assigneeId
        this.statusId = data.statusId
        this.creatorId = data.creatorId
        this.creator = new Employee(data.creator)
        this.createdAt = data.createdAt
        this.updatedAt = data.updatedAt
    }

    get creatorName() {
        return (this.creator?.firstName + ' ' + this.creator?.lastName).toUpperCase()
    }

    get assigneeName() {
        return (this.assignee?.firstName + ' ' + this.assignee?.lastName).toUpperCase()
    }

    get info() {
        let info = ` - ${this.creatorName} assigned to ${this.assigneeName}`

        if (this.creatorId === this.assigneeId) {
            info = ``
        }

        return info
    }

    get statusName() {
        switch (this.statusId as unknown) {
            case TaskStatus.TODO:
                return 'New - Not started'
            case TaskStatus.IN_PROGRESS:
                return 'In progress'
            case TaskStatus.BLOCKED:
                return 'Blocked or on hold'
            case TaskStatus.DONE:
                return 'Completed'
            default:
                return ''
        }
    }

    get isDone() {
        return this.statusId.toString() === TaskStatus.DONE.toString()
    }

    get isInProgress() {
        return this.statusId.toString() === TaskStatus.IN_PROGRESS.toString()
    }

    get isBlocked() {
        return this.statusId.toString() === TaskStatus.BLOCKED.toString()
    }

    get isNew() {
        return this.statusId.toString() === TaskStatus.TODO.toString()
    }

    get taskDate() {
        return dayjs(this.dueDate).format('DD/MM/YYYY')
    }

    get updatedInfo() {
        const updatedDate = dayjs(this.updatedAt).format('DD/MM/YYYY, HH:MM')
        return `Last update on ${updatedDate}`
    }

    get createdInfo() {
        const createdDate = dayjs(this.createdAt).format('DD/MM/YYYY, HH:MM')
        return `Created on ${createdDate} by ${this.creatorName}`
    }

    get isOverdue() {
        return dayjs(this.dueDate).isBefore(new Date(), 'date')
    }

    get isToday() {
        return dayjs(this.dueDate).isSame(new Date(), 'date')
    }

    get isUpcoming() {
        return dayjs(this.dueDate).isAfter(new Date(), 'date')
    }

    get isSelfAssigned() {
        return this.assignee.id === this.creator.id
    }
}

export default Task
